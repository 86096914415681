/** @format */
import { useEffect, useState } from 'react';
import navigationData from './assets/data/mainnav.json';
import './assets/styles/styles.css';
import Header from './components/Header';
import MainNav from './components/MainNav';

function App(props) {
	var location = window.location;

	//Load this string from localStorage
	const lastActiveNav = localStorage.getItem('lastActiveNav');

	//Current page
	const [currentPage, setCurrentPage] = useState(lastActiveNav || 'dashboard');

	//Student view
	const [studentView, setStudentView] = useState(false);

	//Navigation
	const [navItems, setNavItems] = useState(
		navigationData.filter((a) => a.type === 'instructor')
	);
	//Page to load in iFrame
	const [pageToLoad, setPageToLoad] = useState(
		studentView
			? 'https://ux.pearson.com/prototypes/modern-xl-student/page/'
			: 'https://ux.pearson.com/prototypes/modern-xl-instructor/page/'
	);

	const changeStudentView = (e) => {
		let tempNavItems = [];
		if (e.target.checked) {
			setCurrentPage('studentHome');
			tempNavItems = navigationData.filter((a) => a.type === 'student');
		} else {
			setCurrentPage('dashboard');
			tempNavItems = navigationData.filter((a) => a.type === 'instructor');
		}
		setNavItems(tempNavItems);
		setStudentView(e.target.checked);
	};

	const navigatePage = (page) => {
		setCurrentPage(page);
	};

	//This sets the item to localStorage and changes the state index
	function changeCurrentPage(newNav) {
		localStorage.setItem('lastActiveNav', newNav);
		setCurrentPage(newNav);
	}

	//This re-renders when the route changes
	useEffect(() => {
		var activeNavItem = location.pathname;
		if (activeNavItem.indexOf('/') > -1) {
			activeNavItem =
				location.pathname.split('/')[location.pathname.split('/').length - 1];
		}
		if (activeNavItem === 'xl-ov2-modernize') {
			if (studentView) {
				activeNavItem = 'home';
			} else {
				activeNavItem = 'dashboard';
			}
		}
		changeCurrentPage(activeNavItem);
	}, [location]);

	useEffect(() => {
		let navItem = navItems.filter((a) => a.nav === currentPage);
		/* Filter to children if parents don't match*/
		if (navItem.length === 0) {
			let itemsWithChildren = navItems.filter((a) => a.children);
			let childrenNavItem = [];
			itemsWithChildren.forEach((a) => {
				let tempItems = a.children.filter((b) => b.nav === currentPage);
				if (tempItems.length > 0) {
					childrenNavItem.push(tempItems);
				}
			});

			navItem = childrenNavItem[0];
		}

		if (!navItem || !navItem[0]) {
			setPageToLoad(navItems[0].link);
		} else {
			setPageToLoad(navItem[0].link);
		}
	}, [currentPage]);

	return (
		<div className='body-wrap'>
			<Header
				studentView={studentView}
				changeStudentView={changeStudentView}
			/>
			<aside>
				<h2>Mastering A&amp;P</h2>
				<a href='!#'>Back to my courses</a>
				<MainNav
					currentPage={currentPage}
					navItems={navItems}
					navigatePage={navigatePage}
				/>
			</aside>
			<main>
				{}
				<iframe
					src={pageToLoad}
					className='main-iframe'
				/>
			</main>
		</div>
	);
}

export default App;
