/** @format */

// State held component not using Redux
import React, { Component, useState } from 'react';

const Switch = (props) => {
	const [checked, setChecked] = useState(props.checked);
	const [disabled, setDisabled] = useState(props.disabled);

	const onChangeHandle = (e) => {
		setChecked(!checked);
		props.onChange(e);
	};

	return (
		<label className='switch'>
			<input
				type='checkbox'
				checked={checked}
				onChange={(e) => onChangeHandle(e)}
				disabled={disabled}
			/>
			<span className='slider'></span>
		</label>
	);
};

export default Switch;
