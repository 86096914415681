/** @format */
import { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SubMenu from './SubMenu';

const MainNav = (props) => {
	const [openItems, setOpenItems] = useState([]);
	const toggleNavItem = (itemIndex) => {
		let tempOpenItems = openItems;
		let itemPosInArray = tempOpenItems.indexOf(itemIndex);

		if (itemPosInArray > -1) {
			tempOpenItems.splice(itemPosInArray, 1);
		} else {
			tempOpenItems.push(itemIndex);
		}
		setOpenItems(tempOpenItems);
	};
	return (
		<nav className='main-nav'>
			<ul>
				{props.navItems.map((navItem, i) => {
					return (
						<li
							key={`navItem${i}`}
							className={`${navItem.children ? 'has-children' : ''}`}
						>
							{navItem.children ? (
								<SubMenu
									navItem={navItem}
									index={i}
									navigatePage={props.navigatePage}
									currentPage={props.currentPage}
								/>
							) : (
								<button
									className={`${
										props.currentPage === navItem.nav ? 'selected' : ''
									}`}
									onClick={() => props.navigatePage(navItem.nav)}
								>
									{navItem.label}
								</button>
							)}
						</li>
					);
				})}
			</ul>
		</nav>
	);
};

export default MainNav;
