/** @format */
import { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SubMenu = (props) => {
	const [open, setOpen] = useState(false);
	const toggleOpen = () => {
		setOpen(!open);
	};
	return (
		<>
			<button onClick={toggleOpen}>
				{open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
				{props.navItem.label}
			</button>
			<ul className={`${open ? 'open' : ''}`}>
				{props.navItem.children.map((child, j) => {
					return (
						<li key={`navItem${props.index}${j}`}>
							<button
								className={`${
									props.currentPage === child.nav ? 'selected' : ''
								}`}
								onClick={() => props.navigatePage(child.nav)}
							>
								{child.label}
							</button>
						</li>
					);
				})}
			</ul>
		</>
	);
};

export default SubMenu;
